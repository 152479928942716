.screen_reader_hidden {
    position: absolute;
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

:root {
    --lighter-blue: #eff5fc;
    --light-blue: #e9ecfd;
    --cademy-blue: #3d56e0;
    --cademy-blue-dark: #344ee4;
    --brand-color: var(--cademy-blue);
    --on-brand-color: #ffffff;
    --brand-hover-color: color-mix(in srgb, white, var(--brand-color) 10%);
    --blue-hover: #273dba;
    --dark-blue: #101840;
    --accent-one: #f7d154;
    --accent-two: #47b881;
    --accent-three: #ec615b;
    --white: #fff;
    --black: #000;
    --grey: #555;
    --light-grey: #d3d3d3;
    --red: #d14343;
    --red-hover: #f27b7b;
    --orange: #d9822b;
    --yellow: #f7d154;
    --yellow100: #ffefd2;
    --green: #47b881;
    --green100: #dcf2ea;
    --dark-green: #027148;
    --blue: #3366ff;
    --blue400: #5c85ff;
    --blue300: #85a3ff;
    --blue200: #adc2ff;
    --blue100: #d6e0ff;
    --blue50: #ebf0ff;
    --blue25: #f3f6ff;
    --purple: #6e62b6;
    --purple100: #e7e4f9;
    --bg-grey: #f5f6f7;
    --bg-grey-dark: #e8e8e8;
    --bg-1: #fafbff;
    --bg-2: #f5f6f7;
    --bg-3: #f4f6fa;
    --border: #e6e8f0;
    --border-muted: #f1f3f8;
    --text: #000;
    --text-muted: #7b7b7b;
    --paragraph: #000;
    --text-heading: rgb(14, 12, 12);
    --info-bg: var(--blue25);
    --info-border: var(--cademy-blue);
    --warning-bg: #fffaf2;
    --warning-border: #ffb020;
    --danger-bg: #fdf4f4;
    --danger-border: var(--red);
    --success-bg: #dbf6e7;
    --success-border: var(--green);
    --card-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.02);
    --card-box-shadow-hover: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    --facebook-blue: #4267b2;
    --instagram-red: #c13584;
    --twitter-blue: #1da1f2;
    --pinterest-red: #e60023;
    --linkedin-blue: #0072b1;
    --youtube-red: #ff0000;
    --twitter-x-black: #000000;
    --icon-high-rating: #007500;
    --icon-low-rating: #8b0000;
    --icon-fill-light: #c5c5c5;
    --item-bg-light: #e0e7edc0;
    --link-color: var(--cademy-blue);
    --cademy-brand-color: var(--cademy-blue);
    --on-cademy-brand-color: #ffffff;

    --font-family: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
        Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    --font-size: 1em; // 16px;
    --font-size-smaller: 0.875em; // 14px
    --font-size-extra-small: 0.75em; // 12px
    --font-size-tiny: 0.5em; // 8px
    --font-size-bigger: 1.125em; // 18px
    --font-size-h1: 2em; // 32px
    --font-size-h2: 1.5em; // 24px
    --font-size-h3: 1.125em; // 18px

    --font-weight-normal: 400;
    --font-weight-light: 300;
    --font-weight-bolder: 500;
    --font-weight-bold: 600;

    --padding: 1.25em; // 20px
    --padding-small: 0.75em; // 12px
    --padding-smaller: 0.6em; // 9.6px
    --padding-tiny: 0.325em; // 5.2px

    --margin: 0.625em; // 10px
    --margin-big: 0.9375em; // 15px
    --margin-bigger: 1.5625em; // 25px
    --margin-small: 0.3125em; // 5px
    --margin-giant: 3.125em; //50px;

    --gap-list-smaller: 0.3125em; // 5px
    --gap-list-small: 0.625em; // 10px
    --gap-list: 0.9375em; // 15px
    --gap-list-large: 1.5em;
    --section-gap-big: 3.125em; //50px
    --section-gap-small: 1.25em; // 20px

    --rounded-corners-standard: 5px;
    --rounded-corners-pronounced: 10px;
    --rounded-corners-pill: 25px;

    --border-radius: var(--rounded-corners-standard);

    --screen-smaller: 375px;
    --screen-small: 767px;
    --screen-medium: 1280px;

    --scroll: 0;
}

@font-face {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    src: local(var(--font-family));
    font-display: fallback;
}

html,
body {
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--text);
    margin: 0;
    font-size: var(--font-size);
    scroll-behavior: smooth;
}

button {
    font-family: var(--font-family);
}

input,
textarea {
    font-family: var(--font-family);
}

a {
    text-decoration: none;
    color: var(--link-color);
}

p {
    font-size: var(--font-size);
    line-height: var(--font-size-h2);
    letter-spacing: -0.05px;
}

h1 {
    font-size: var(--font-size-h1);
    font-weight: 800;
    margin-block-end: var(--margin);
}
h2 {
    font-size: var(--font-size-h2);
}
h3 {
    font-size: var(--font-size-h3);
}

.rounded_image {
    img {
        object-fit: cover;
        object-position: center;
        border-radius: var(--border-radius);
        //below is needed to make rounded corners work
        position: relative !important;
        margin: var(--padding-tiny) auto;
        display: block;
    }
}

html,
body {
    height: 100%;
}

.container {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 var(--padding);
}

.cleaned_list_starter {
    margin: 0;
    padding: 0;
    list-style: none;
}

.profiles_icon {
    border-radius: 50%;
    padding: var(--padding-small);
    height: 1.25em;
    width: 1.25em;
}

.facebook_icon,
.instagram_icon,
.twitter_icon,
.pinterest_icon,
.linkedin_icon,
.youtube_icon,
.email_icon,
.web_link_icon,
.twitter_x_icon {
    fill: var(--white);

    &:hover {
        opacity: 0.8;
    }
}

.facebook_icon {
    background: var(--facebook-blue);
    overflow: visible;
}

.instagram_icon {
    background: var(--instagram-red);
}

.twitter_icon {
    background: var(--twitter-blue);
    overflow: visible;
}

.twitter_x_icon {
    background: var(--twitter-x-black);
}

.pinterest_icon {
    background: var(--pinterest-red);
}

.linkedin_icon {
    background: var(--linkedin-blue);
    overflow: visible;
}

.youtube_icon {
    background: var(--youtube-red);
}

.web_link_icon {
    background: var(--link-color);
    fill: white;
}

.email_icon {
    background: var(--dark-green);
    fill: white;
    overflow: visible;
}

.section_heading {
    font-size: 2.5rem;
    line-height: 3rem;
    letter-spacing: 0px;
    font-weight: 700;
    text-align: center;
    margin-block-start: 50px;
    margin-block-end: 50px;
    padding-inline: 1em;
    text-wrap: balance;
}

.reviewContainer {
    display: flex;
    justify-content: center;
}

.reviewSection {
    border: 1px solid var(--border);
    border-radius: var(--border-radius);
    margin-block: 5em;
    padding-block: 2em;
    padding-inline: 3em;
    width: 80%;
    max-width: 700px;
}

@media screen and (min-width: 500px) {
    .reviewSection {
        width: 75%;
    }
}

@media screen and (min-width: 767px) {
    .reviewSection {
        width: 50%;
    }
}

@media screen and (min-width: 1200px) {
    .reviewSection {
        width: 33%;
    }
}

@media screen and (max-width: 767px) {
    .container {
        padding: 0 var(--padding);
        margin: auto auto;
    }
}

.CademyRichtext_CourseDescription_Column {
    flex-grow: 1;
    padding: var(--padding-small);
}

.CademyRichtext_CourseDescription_ColumnGroup {
    display: grid;
    gap: var(--gap-list);
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
}

.CademyRichtext_CourseDescription_Indent {
    margin-inline-start: var(--padding);
}

.CademyRichtext_CourseDescription_Image {
    display: flex;
    width: 100%;
    justify-content: space-around;
}
